import 'core-js/actual/promise';
import request from "superagent";

// START
$(() => {
  activateForm();
  setInitialSearch();
});


window.opacState = {
  'listing': {
    'id': "opac-listing",
    'isLoading': true
  },
	'search': {
  	'selected_facets': [],
		'query':'',
		'num_results': 50,
		'page': 1
	}
};

function setInitialSearch(){
	let query_string = getQueryStringValue('q', true);
	if (typeof(opacNumResults) !== "undefined") {
		opacState.search.num_results = opacNumResults;
	}
	if ((typeof(opacPredefinedSearch) !== "undefined") && (opacPredefinedSearch !== false)){
		// override with predefined one
		opacState.search.query = opacPredefinedSearch;
		opacState.search.page = getQueryStringValue('page', true);
		doQuery();
	}else if (query_string.length > 0) {
		$("#library-search-input").val(query_string);
		opacState.search.query = query_string;
		opacState.search.page = getQueryStringValue('page', true);
		doQuery();
	}
}


function activatePagination(){
  $('.pagination__item a').click(function (e) {
  	opacState.search.page = $(this).data().pageNo;
  	doQuery();
  	e.preventDefault();
  });
}

function doQuery() {
  /* Run the search and display results */
  opacState.listing.isLoading = true;
  request
    .get('/search/opac/')
    .query({'q' : opacState.search.query})
    .query({'num_results' : opacState.search.num_results})
	  .query({'page': opacState.search.page})
    .then(result => {
      if (result.body.success) {
        $(`#${opacState.listing.id}`).html(result.body.html);
        opacState.listing.isLoading = false;
        activatePagination();
      }
      else {
        console.log("Incorrect search");
        opacState.listing.isLoading = false;
      }
    })
    .catch(err => {
      console.log(err.message, []);
      opacState.listing.isLoading = false;
    });
  updateUrlBasedOnQuery();
}

function getQueryStringValue(name, as_string=false) {
   let qs = location.search;

   let params = [];
   let tokens;
   let re = /[?&]?([^=]+)=([^&]*)/g;

   while (tokens = re.exec(qs))
   {
       if (decodeURIComponent(tokens[1]) == name)
       params.push(decodeURIComponent(tokens[2]));
   }

   if (as_string) {
   	if (params.length >= 1){
   	  return params[0];
    }else{
   		return "";
    }
   }
	 return params;
}

function activateForm() {
  opacState.form = $("#opac-form");

  $('#library-search-submit').click(function (e) {
  	// reset page if you're doing a new query
	  opacState.search.page = 1;
  	doQuery();
  	e.preventDefault();
  });

  $('#library-search-input').keyup(function (e) {
  	opacState.search.query = $(this).val();
  });
}

function updateUrlBasedOnQuery() {
  let url = window.location.pathname + '?q=' + opacState.search.query;
  url += "&page=" + opacState.search.page;
  window.history.replaceState(null, null, url);
}
